import { render, staticRenderFns } from "./ExpireForgotLink.vue?vue&type=template&id=5092daea&scoped=true"
import script from "./ExpireForgotLink.vue?vue&type=script&lang=js"
export * from "./ExpireForgotLink.vue?vue&type=script&lang=js"
import style0 from "./ExpireForgotLink.vue?vue&type=style&index=0&id=5092daea&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5092daea",
  null
  
)

export default component.exports